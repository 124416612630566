@import "../../variables.scss";

.side-drawer {
  position: fixed;
  top: 87px;
  left: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  width: 500px;
  box-shadow: 5px 5px 5px rgba(205, 205, 205, 0.3);
  transform: translateX(-500px);
  transition: 300ms ease-in-out;
  z-index: 400;

  &--open {
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  &__nav-link {
    text-decoration: none;
    margin-bottom: 30px;
    color: $contrastColour;
    font-size: 17px;
    transition: 300ms;
    margin-left: 30px;

    &:hover {
      color: goldenrod;
      transition: 300ms;
    }
  }
}

@media (max-width: 700px) {
  .side-drawer {
    top: 100px;
  }
}
