@import "../../variables.scss";
.nav {
  display: flex;
  margin-bottom: 24px;
}

.nav-link {
  text-decoration: none;
  margin-right: 24px;
  color: black;
}

.active {
  color: $contrastColour;
}

@media (max-width: 900px) {
  .nav {
    display: none;
  }
}
