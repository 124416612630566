@import "../../variables.scss";

.ensembles {
  display: flex;
  flex-direction: column;

  &__ensemble {
    padding: 30px;
    background-color: whitesmoke;
    margin: 30px;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__title {
      color: $contrastColour !important;
    }

    &__text {
      width: 80%;
      max-width: 900px;
    }

    &__image {
      width: 80%;
      max-width: 900px;
      margin: auto;
      & img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 700px) {
  .ensembles {
    &__ensemble {
      padding: 0px;
      margin: 20px 0;
    }

    &__text {
      width: 100%;
    }

    &__image {
      width: 100%;
    }
  }
}
