@import "../../variables.scss";

.teaching-page {
  padding: 30px;
  background-color: $transparentBgd;
  text-align: justify;

  & h2 {
    color: $contrastColour;
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__image {
      width: 20%;
      min-width: 200px;
      margin: 8px;

      @media (max-width: 768px) {
        width: 45%;
      }

      img {
        width: 100%;
      }
    }
  }

  &__image-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 50px 0;
  }

  &__image--l {
    width: 60%;

    margin: 8px;

    & img {
      width: 100%;
    }
  }

  &__image--p {
    flex: 1;
    margin: auto;

    & img {
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .teaching-page {
    &__image-container {
      flex-direction: column;
    }

    &__image--l {
      width: 100%;
    }
  }
}
