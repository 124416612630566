.hero {
  background-color: black;
  display: flex;
  justify-content: space-between;
  color: white;
  // padding-top: 150px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("../../assets/kbdBgd.JPG");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  &__testimonials {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__image {
    width: 50vw;
    max-width: 600px;
    min-width: 300px;
    & img {
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .hero {
    // flex-direction: column-reverse;
    &__image {
      width: auto;
      height: 100%;
    }
  }
}

@media (max-width: 700px) {
  .hero {
    flex-direction: column-reverse;

    &__image {
      width: 100%;
      max-width: 100vw;
    }
  }
}
