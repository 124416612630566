.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &__image {
    width: 300px;
    margin-bottom: 30px;
    & img {
      width: 100%;
    }
  }
}
