@import "../../variables.scss";

.page-title {
  // position: sticky;
  // top: 60px;
  // background-color: rgba(255, 255, 255, 0.8);
  // backdrop-filter: blur(5px);
  padding: 10px;
  // max-width: 1300px;
  margin: auto;
  font-family: "Lora", serif;
  color: $contrastColour;
}

.white {
  color: white;
}
