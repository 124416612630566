.recording-card {
  background-color: whitesmoke;
  max-width: 1300px;
  padding: 20px;
  margin: auto;
  margin-bottom: 40px;

  &__section {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    max-width: 500px;
    min-width: 300px;
    width: 50%;
    margin-right: 16px;
    & img {
      width: 100%;
    }
  }

  &__videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    & iframe {
      width: 20%;
      height: 200px;
      min-width: 300px;
      margin: 10px;
    }
  }
}
