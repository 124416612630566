@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import "../../variables.scss";

.header {
  font-family: "Lora", serif;
  padding: 1px 30px;
  position: sticky;
  top: -70px;
  background-color: whitesmoke;
  color: $contrastColour;
  z-index: 5;

  &--scrolled {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    transition: 300ms;
  }
}

.menu-toggle {
  display: none;
}

@media (max-width: 899px) {
  .menu-toggle {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
    color: $contrastColour;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 300;
    font-family: "Lora", serif;
  }
}

@media (max-width: 900px) {
  .header {
    position: fixed;
    width: 100%;
    font-size: 12px;
    top: 0;
    padding-right: 30px;
  }
}

@media (max-width: 700px) {
  .header {
    width: 100vw;
    padding: 0 16px;
    font-size: 10px;

    & h1 {
      width: 80vw;
    }
  }
  .menu-toggle {
    font-size: 12px;
  }
}
