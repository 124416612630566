@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.testimonials-container {
  // height: 400px;
  transition: 300ms;
  font-family: "Lora", serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  &__quote {
    font-size: 21px;
    font-style: italic;
  }

  &__source {
    font-size: 15px;
  }
  &__image {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 20px;

    & img {
      max-width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}

.slide-item {
  padding: 40px;
  width: 400px;
  max-width: 100%;
  margin: auto;
  text-align: center;
}

@media (max-width: 700px) {
  .slide-item {
    width: 50%;
    padding: 20px;
  }

  .testimonials-container {
    height: 300px;
  }
}
