.biog-page {
  max-width: 1200px;
  margin: auto;
  text-align: justify;

  & img {
    width: 100%;
    max-width: 600px;
  }
}

@media (min-width: 700px) {
  .biog-page {
    columns: 2;
    column-gap: 2rem;
    -moz-columns: 2;
  }
}
